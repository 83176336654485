/*******************************
         Site Overrides
*******************************/
h4.ui.header,
h5.ui.header {
    font-weight: @bold;
}

.ui.menu .primary.header.item {
    color: @primaryColor;
}

.ui.menu .secondary.header.item {
    color: @secondaryColor;
}