/*******************************
         Site Overrides
*******************************/
/*--- primary ---*/
.ui.primary.labels .label,
.ui.primary.label {
  background-color: @primaryColor !important;
  border-color: @primaryColor !important;
  color: @white !important;
}


/*--- secondary ---*/
.ui.secondary.labels .label,
.ui.secondary.label {
  background-color: @secondaryColor !important;
  border-color: @secondaryColor !important;
  color: @white !important;
}
