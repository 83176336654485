/*******************************
         Site Overrides
*******************************/




@media only screen and (max-width: @computerBreakpoint) {
    .ui.very.padded.segment {
        padding: 1.5em;
        
    }
    
}

@media only screen and (max-width: @tabletBreakpoint) {
    .ui.very.padded.segment,
    .ui.raised.segments {
        padding: 1px;
        box-shadow: none;
        background: transparent;
        border: none;
    }
    .ui.raised.segments .segment{
        padding-left: 0;
        padding-right: 0;
    }


    .segments .segment .ui.stackable.grid> .column {
        padding: 1rem 0 !important;
    }

}
