/*******************************
         Site Overrides
*******************************/

.site-container {
  padding-bottom: 20px; 
}

.page-container {
  display: flex;
}
.sidebar-container {
  position: fixed;
  z-index: 101;
  background-color: @white;
  box-shadow: @subtleShadow;
  width: 275px;
  flex: 0;
  height: 100vh;
  &.inverted {
    background: @black;
  }
  &.primary {
    background: @primaryColor;
  }
  .ui.vertical.menu {
    border-bottom: none !important;
  }
  .ui.menu .item {
    padding-top:1.1em;
    padding-bottom: 1.1em;
    &:before {
      display: none; 
    }
  }
  .ui.vertical.menu .item>i.icon {
    float: none;
    margin-left: 0;
    color: @grey;
    margin-right: 8px;
    &.dropdown {
      float: right;
      margin-right: 0;
    }
  }
}

label {
  font-weight: bold;
}

.clickable {
  cursor: pointer;
}

.margin-right {
  margin-right: 1em;
}

.link {
  color: @primaryColor;
  text-decoration: underline;
}



.app-logo {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
  text-align: center;
  //font-weight: @bold !important;
  text-transform: uppercase !important;
  letter-spacing: 1px;
  color: @white !important;
  background-color: @primaryColor !important;
}

.sidebar-container .ui.vertical.menu {
  box-shadow: none;
  border: none;
  border-bottom: 1px solid rgba(34,36,38,.1);
  border-radius: 0;
}
.content-container {
  flex: 1 1 auto;
  min-width: 0px;
  margin-left: 275px;
  padding: 3em;
}

.mobile-header {
  display: none;
  .ui.menu .item:before {
    display: none; //remove border from items
  }
}

.ui.grid .column.white {
  background-color: @white !important;
}

.luvat .ui.circular.label {
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.drop.zone {
  display: flex; 
  align-items: center;
  border:  2px dashed @lightGrey;
  padding: 1rem;
  border-radius: @defaultBorderRadius*2;
  background: @offWhite;
  margin-bottom: 1em;
  transition: all 0.2s ease-out;
 .drop.text {
    padding-left: 1em;
    padding-right: 1em;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.success {
    background: @greenBackground;
    border-color: @lightGreen;
  }
  &.loading {
    background: @blueBackground;
    border-color: @lightBlue;
  }
  &.invalid {
    background: @yellowBackground;
    border-color: @lightYellow;
  }
  &.error {
    background: @redBackground;
    border-color: @lightRed;
  }
  .images-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
}
.uploaded-images {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
 .uploaded-image {
    position: relative;
    display: inline-block;
    margin-right: 1em;
    .floating.ui.black.label {
      background: rgba(0,0,0,0.7) !important; 
    }
  }
}

.front-page-box-text {
  margin: 1em 1em 0;
}

@media only screen and (max-width: @largestTabletScreen) {

  .mobile-header {
    display: flex;
  }
  
  .page-container {
    display: block;
  }
  
  .content-container {
    margin-left: 0;
    padding: 0.75em;
  }
  .sidebar-container {
    margin-top: 3em;
    transform: translateX(-300px);
    transition: all @defaultDuration @defaultEasing;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
                0 10px 10px rgba(0,0,0,0.22);
    &.active {
      transform: translateX(0);
    }
  }
}


@logo-max-width: 200px;
@sg-top-height: 100px;

@padding: 12px;

.sg-top-logo {
    max-width: @logo-max-width;
    max-height: @sg-top-height;
    padding: @padding;
}

.sg-header-nav {
    margin-bottom: 1em;
    position: relative;
}

.sg-top-buttons {
    height: @sg-top-height;
    padding-right: @padding;

    p {
        line-height: @sg-top-height;
    }

    .button {
        margin: @padding / 2;
    }
}

@media all and (max-width: 768px) {
    .sg-header-nav > .ui.menu:not(.sg-menu-visible) {
      &> .menu.right.collapseMenu {
          display: none !important;
      }
    }

    .sg-header-nav .ui.menu.sg-menu-visible {
      display: none;
    }

    .sg-header-nav .ui.menu .ui.primary.button.menuButton {
      right: 0 !important;
      top: 6px !important;
      position: absolute !important;
      float: right !important;
      display: initial !important;
    }
}
@media all and (min-width: 768px) {
  .sg-header-nav .ui.menu .ui.primary.button.menuButton {
    display: none;
  }
}


/* Semantic UI has these classes, however they're only applicable to*/
/* grids, containers, rows and columns.*/
/* plus, there isn't any `mobile hidden`, `X hidden` class.*/
/* this snippet is using the same class names and same approach*/
/* plus a bit more but to all elements.*/
/* see https://github.com/Semantic-Org/Semantic-UI/issues/1114*/

/* Mobile */
@media only screen and (max-width: @largestMobileScreen) {
  [class*="mobile hidden"],
  [class*="tablet only"]:not(.mobile),
  [class*="computer only"]:not(.mobile),
  [class*="large screen only"]:not(.mobile),
  [class*="widescreen only"]:not(.mobile),
  [class*="or lower hidden"] {
    display: none !important;
  }
  body {
    padding-top: 10px;
  }

  .sg-header-top .ui.grid {
    margin-left: 0;
    margin-right: 0;
  }

  .sg-header-top {
    .sg-top-buttons {
      padding-right: 0;
    }
    .sg-top-logo {
      padding-left: 0;
    }
  }

 

  .sg-header-nav .ui.menu,
  .sg-header-nav .ui.menu .right.menu {
    display:block !important;
  }

  .ui.buttons.table-filters {
    flex-wrap: wrap;
    .ui.button {
      border-bottom: 1px solid @strongTransparentBlack;
      
    }
  }

  .ui.stackable.grid.form-grid  {
    .row .wide.column,
    .row .column,
    .wide.column,
    .column,
    .column:not(.row) {
      padding: 0.5em 0 0.5em !important;
    }
  }
}

.ui.message.fixed-error-msg {
  position: fixed;
  bottom: 100px;
  left: 10%;
  width: 80%;
}

/* Tablet / iPad Portrait */
@media only screen and (min-width: @tabletBreakpoint) and (max-width: @largestTabletScreen) {
  [class*="mobile only"]:not(.tablet),
  [class*="tablet hidden"],
  [class*="computer only"]:not(.tablet),
  [class*="large screen only"]:not(.tablet),
  [class*="widescreen only"]:not(.tablet),
  [class*="or lower hidden"]:not(.mobile) {
    display: none !important;
  }
}

/* Computer / Desktop / iPad Landscape */
@media only screen and (min-width: @computerBreakpoint) and (max-width: @largestSmallMonitor) {
  [class*="mobile only"]:not(.computer),
  [class*="tablet only"]:not(.computer),
  [class*="computer hidden"],
  [class*="large screen only"]:not(.computer),
  [class*="widescreen only"]:not(.computer),
  [class*="or lower hidden"]:not(.tablet):not(.mobile) {
    display: none !important; 
  }
}

/* Large Monitor */
@media only screen and (min-width: @largeMonitorBreakpoint) and (max-width: @largestLargeMonitor) {
  [class*="mobile only"]:not([class*="large screen"]),
  [class*="tablet only"]:not([class*="large screen"]),
  [class*="computer only"]:not([class*="large screen"]),
  [class*="large screen hidden"],
  [class*="widescreen only"]:not([class*="large screen"]),
  [class*="or lower hidden"]:not(.computer):not(.tablet):not(.mobile) {
    display: none !important;
  }
}

/* Widescreen Monitor */
@media only screen and (min-width: 1920px) {
  [class*="mobile only"]:not([class*="widescreen"]),
  [class*="tablet only"]:not([class*="widescreen"]),
  [class*="computer only"]:not([class*="widescreen"]),
  [class*="large screen only"]:not([class*="widescreen"]),
  [class*="widescreen hidden"],
  [class*="widescreen or lower hidden"] {
    display: none !important;
  }
}

@media print {

  body {
    margin-left: 50px;
    margin-right: 50px;
  }

  .sg-top-logo {
    width: 200px !important;
    height: auto !important;
  }

  .sg-header-nav,
  .sg-top-buttons.middle,
  .ui.buttons.table-filters,
  .hide-from-print {
    display: none !important;
  }

}