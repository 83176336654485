/*******************************
         Site Overrides
*******************************/

/* Menu shadow */
.ui.shadow.menu {
    box-shadow: @subtleShadow;
}

/* Primary */
.ui.inverted.menu .primary.active.item,
.ui.inverted.primary.menu {
  background-color: @primaryColor;
}
.ui.inverted.primary.menu .item:before {
  background-color: @invertedColoredDividerBackground;
}
.ui.inverted.primary.menu .active.item {
  background-color: @invertedColoredActiveBackground !important;
}

